import React from 'react';
import { createPaneview, } from 'dockview-core';
import { usePortalsLifecycle } from '../react';
import { PanePanelSection } from './view';
export const PaneviewReact = React.forwardRef((props, ref) => {
    const domRef = React.useRef(null);
    const paneviewRef = React.useRef();
    const [portals, addPortal] = usePortalsLifecycle();
    React.useImperativeHandle(ref, () => domRef.current, []);
    React.useEffect(() => {
        const createComponent = (id, _componentId, component) => new PanePanelSection(id, component, {
            addPortal,
        });
        const api = createPaneview(domRef.current, {
            disableAutoResizing: props.disableAutoResizing,
            frameworkComponents: props.components,
            components: {},
            headerComponents: {},
            disableDnd: props.disableDnd,
            headerframeworkComponents: props.headerComponents,
            frameworkWrapper: {
                header: {
                    createComponent,
                },
                body: {
                    createComponent,
                },
            },
            showDndOverlay: props.showDndOverlay,
        });
        const { clientWidth, clientHeight } = domRef.current;
        api.layout(clientWidth, clientHeight);
        if (props.onReady) {
            props.onReady({ api });
        }
        paneviewRef.current = api;
        return () => {
            api.dispose();
        };
    }, []);
    React.useEffect(() => {
        if (!paneviewRef.current) {
            return;
        }
        paneviewRef.current.updateOptions({
            frameworkComponents: props.components,
        });
    }, [props.components]);
    React.useEffect(() => {
        if (!paneviewRef.current) {
            return;
        }
        paneviewRef.current.updateOptions({
            headerframeworkComponents: props.headerComponents,
        });
    }, [props.headerComponents]);
    React.useEffect(() => {
        if (!paneviewRef.current) {
            return () => {
                //
            };
        }
        const api = paneviewRef.current;
        const disposable = api.onDidDrop((event) => {
            if (props.onDidDrop) {
                props.onDidDrop(Object.assign(Object.assign({}, event), { api }));
            }
        });
        return () => {
            disposable.dispose();
        };
    }, [props.onDidDrop]);
    React.useEffect(() => {
        if (!paneviewRef.current) {
            return;
        }
        paneviewRef.current.updateOptions({
            showDndOverlay: props.showDndOverlay,
        });
    }, [props.showDndOverlay]);
    return (React.createElement("div", { className: props.className, style: { height: '100%', width: '100%' }, ref: domRef }, portals));
});
PaneviewReact.displayName = 'PaneviewComponent';
