/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */

import useLayoutEffect from '@react-hook/passive-layout-effect';
import useLatest from '@react-hook/latest';

/**
 * A React hook that fires a callback whenever ResizeObserver detects a change to its size
 *
 * @param target A React ref created by `useRef()` or an HTML element
 * @param callback Invoked with a single `ResizeObserverEntry` any time
 *   the `target` resizes
 */

function _ref() {}
function useResizeObserver(target, callback, options = {}) {
  const resizeObserver = getResizeObserver(options.polyfill);
  const storedCallback = useLatest(callback);
  useLayoutEffect(() => {
    let didUnsubscribe = false;
    const targetEl = target && 'current' in target ? target.current : target;
    if (!targetEl) return _ref;
    function cb(entry, observer) {
      if (didUnsubscribe) return;
      storedCallback.current(entry, observer);
    }
    resizeObserver.subscribe(targetEl, cb);
    return () => {
      didUnsubscribe = true;
      resizeObserver.unsubscribe(targetEl, cb);
    };
  }, [target, resizeObserver, storedCallback]);
  return resizeObserver.observer;
}
function createResizeObserver(polyfill) {
  let ticking = false;
  let allEntries = [];
  const callbacks = new Map();
  const observer = new (polyfill || window.ResizeObserver)((entries, obs) => {
    allEntries = allEntries.concat(entries);
    function _ref2() {
      const triggered = new Set();
      for (let i = 0; i < allEntries.length; i++) {
        if (triggered.has(allEntries[i].target)) continue;
        triggered.add(allEntries[i].target);
        const cbs = callbacks.get(allEntries[i].target);
        cbs === null || cbs === void 0 ? void 0 : cbs.forEach(cb => cb(allEntries[i], obs));
      }
      allEntries = [];
      ticking = false;
    }
    if (!ticking) {
      window.requestAnimationFrame(_ref2);
    }
    ticking = true;
  });
  return {
    observer,
    subscribe(target, callback) {
      var _callbacks$get;
      observer.observe(target);
      const cbs = (_callbacks$get = callbacks.get(target)) !== null && _callbacks$get !== void 0 ? _callbacks$get : [];
      cbs.push(callback);
      callbacks.set(target, cbs);
    },
    unsubscribe(target, callback) {
      var _callbacks$get2;
      const cbs = (_callbacks$get2 = callbacks.get(target)) !== null && _callbacks$get2 !== void 0 ? _callbacks$get2 : [];
      if (cbs.length === 1) {
        observer.unobserve(target);
        callbacks.delete(target);
        return;
      }
      const cbIndex = cbs.indexOf(callback);
      if (cbIndex !== -1) cbs.splice(cbIndex, 1);
      callbacks.set(target, cbs);
    }
  };
}
let _resizeObserver;
const getResizeObserver = polyfill => !_resizeObserver ? _resizeObserver = createResizeObserver(polyfill) : _resizeObserver;
export default useResizeObserver;