import React from 'react';
import { Orientation, createSplitview, } from 'dockview-core';
import { usePortalsLifecycle } from '../react';
import { ReactPanelView } from './view';
export const SplitviewReact = React.forwardRef((props, ref) => {
    const domRef = React.useRef(null);
    const splitviewRef = React.useRef();
    const [portals, addPortal] = usePortalsLifecycle();
    React.useImperativeHandle(ref, () => domRef.current, []);
    React.useEffect(() => {
        var _a;
        const api = createSplitview(domRef.current, {
            disableAutoResizing: props.disableAutoResizing,
            orientation: (_a = props.orientation) !== null && _a !== void 0 ? _a : Orientation.HORIZONTAL,
            frameworkComponents: props.components,
            frameworkWrapper: {
                createComponent: (id, componentId, component) => {
                    return new ReactPanelView(id, componentId, component, {
                        addPortal,
                    });
                },
            },
            proportionalLayout: typeof props.proportionalLayout === 'boolean'
                ? props.proportionalLayout
                : true,
            styles: props.hideBorders
                ? { separatorBorder: 'transparent' }
                : undefined,
        });
        const { clientWidth, clientHeight } = domRef.current;
        api.layout(clientWidth, clientHeight);
        if (props.onReady) {
            props.onReady({ api });
        }
        splitviewRef.current = api;
        return () => {
            api.dispose();
        };
    }, []);
    React.useEffect(() => {
        if (!splitviewRef.current) {
            return;
        }
        splitviewRef.current.updateOptions({
            frameworkComponents: props.components,
        });
    }, [props.components]);
    return (React.createElement("div", { className: props.className, style: { height: '100%', width: '100%' }, ref: domRef }, portals));
});
SplitviewReact.displayName = 'SplitviewComponent';
