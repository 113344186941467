var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { CloseButton } from '../svg';
function useTitle(api) {
    const [title, setTitle] = React.useState(api.title);
    React.useEffect(() => {
        const disposable = api.onDidTitleChange((event) => {
            setTitle(event.title);
        });
        return () => {
            disposable.dispose();
        };
    }, [api]);
    return title;
}
export const DockviewDefaultTab = (_a) => {
    var { api, containerApi: _containerApi, params: _params, hideClose, closeActionOverride } = _a, rest = __rest(_a, ["api", "containerApi", "params", "hideClose", "closeActionOverride"]);
    const title = useTitle(api);
    const onClose = React.useCallback((event) => {
        event.preventDefault();
        if (closeActionOverride) {
            closeActionOverride();
        }
        else {
            api.close();
        }
    }, [api, closeActionOverride]);
    const onPointerDown = React.useCallback((e) => {
        e.preventDefault();
    }, []);
    const onClick = React.useCallback((event) => {
        if (event.defaultPrevented) {
            return;
        }
        api.setActive();
        if (rest.onClick) {
            rest.onClick(event);
        }
    }, [api, rest.onClick]);
    return (React.createElement("div", Object.assign({ "data-testid": "dockview-dv-default-tab" }, rest, { onClick: onClick, className: "dv-default-tab" }),
        React.createElement("span", { className: "dv-default-tab-content" }, title),
        !hideClose && (React.createElement("div", { className: "dv-default-tab-action", onPointerDown: onPointerDown, onClick: onClose },
            React.createElement(CloseButton, null)))));
};
